import { useQuery } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import SupportedLanguagesService from '../../services/SupportedLanguagesService';
export const useListSupportedLanguages = () => {
    const allSupportedLanguages = useQuery({
        queryKey: [QueryKeys.LANGUAGE_SUPPORTED],
        queryFn: () => SupportedLanguagesService.supported(),
        staleTime: Infinity,
    });
    return {
        allSupportedLanguages,
    };
};
