import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createRoot } from 'react-dom/client';
import ErrorBoundary from 'src/ui/ErrorBondary';
import { Toast } from 'src/ui/cosmos/Toast';
import CosmosComponents from 'src/infrastructure/config/styles/cosmosComponents';
import '../config/hot-observability';
import 'src/infrastructure/config/styles/cosmos';
import 'src/application/styles/global.scss';
import ReactQueryProvider from 'src/infrastructure/libs/ReactQuery';
import { AbilityProvider } from 'src/infrastructure/libs/CASL';
import { MediaUploadProvider } from 'src/domain/Media/modules/MediaUpload/contexts/MediaUploadContext';
import AuthProvider from './application/context/Auth/AuthContext';
import { RouterProvider } from './application/router';
const rootElement = document.querySelector('#root');
if (!rootElement)
    throw new Error('Failed to find the root element');
const root = createRoot(rootElement);
import './infrastructure/config/i18n';
import { Csat } from './infrastructure/libs/Csat';
import { isProduction } from './utils/env';
import FruitsOnboarding from './infrastructure/libs/Fruits';
root.render(_jsxs(ErrorBoundary, { children: [_jsx(CosmosComponents, {}), _jsx(Toast, {}), _jsxs(AuthProvider, { children: [isProduction() && _jsx(Csat, {}), _jsx(FruitsOnboarding, {}), _jsx(AbilityProvider, { children: _jsx(ReactQueryProvider, { children: _jsx(MediaUploadProvider, { children: _jsx(RouterProvider, {}) }) }) })] })] }));
