import Api from 'src/infrastructure/services/Api';
const list = async () => {
    const response = await Api.call({
        method: 'GET',
        url: `${process.env.API_GATEWAY}/chapter/generate/status`,
    });
    return response.data;
};
const generate = async ({ mediaCodes, language, }) => {
    const response = await Api.call({
        method: 'POST',
        url: `${process.env.API_GATEWAY}/chapter/generate`,
        data: { mediaCodes, language },
    });
    return response.data;
};
const retry = async (id) => {
    const response = await Api.call({
        method: 'POST',
        url: `${process.env.API_GATEWAY}/chapter/generate/${id}/reprocess`,
    });
    return response.data;
};
export { list, generate, retry };
