import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from 'src/application/icons/fontAwesome';
import { faSpinnerThird } from 'src/application/icons/fontAwesome/regular';
import { TextTruncate } from 'src/ui/TextTruncate';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
const Optimizing = ({ item }) => {
    const { t } = useTranslation();
    return (_jsxs("div", { "data-testid": 'transfer-state-manager-optimizing', className: styles.optimizing, children: [_jsxs("div", { className: styles.name, children: [_jsx(TextTruncate, { children: _jsx("div", { className: styles.fileName, children: item.title }) }), _jsx("span", { children: t('upload.upload_status.optimizing.message') })] }), _jsx("div", { className: styles.icon, children: _jsx(FontAwesomeIcon, { icon: faSpinnerThird, spin: true, size: 'lg' }) })] }));
};
export default Optimizing;
