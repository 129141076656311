import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useEffect } from 'react';
import { Can } from 'src/infrastructure/libs/CASL';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import linkImg from 'public/assets/svg/link.svg';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import useFeatureToggle from 'src/application/hooks/useFeatureToggle';
import { FeatureToggle } from 'src/application/interfaces/FeatureToggle';
import useAccounts from 'src/domain/User/Accounts/hooks/useAccounts';
import { useFileUploadProcess } from '../contexts/MediaUploadContext/hooks/useFileUploadProcess';
import { GeneralStatus } from '../interfaces/FileUploadProps';
import { useRemoveFileOnUpload } from '../contexts/MediaUploadContext/hooks/useRemoveFileOnUpload';
import { TIME_TO_CLOSE_FRAME_UPLOAD_AFTER_UPLOADS_COMPLETED } from '../contexts/MediaUploadContext/constants';
import { FileUpload } from '../containers/FileUpload';
import ImportMediaProviderList from '../components/ImportMediaProvider';
import { SubtitleBatchItems } from '../../Subtitle/containers/SubtitleBatchItems';
import ChaptersBatchItems from '../../Chapters/components/ChaptersBatchItems';
import styles from './styles.module.scss';
export const FileUploadListContainer = forwardRef((_, ref) => {
    const { t, i18n } = useTranslation();
    const { generalUploadState } = useFileUploadProcess();
    const { removeAllFileFromList } = useRemoveFileOnUpload();
    const { currentAccount } = useAccounts();
    const { isEnabled } = useFeatureToggle({
        accountOwnerMarketplaceId: currentAccount.accountOwnerMarketplaceId,
    });
    const isAdditionalContentEnabled = isEnabled(FeatureToggle.HOTMART_PLAYER_ENABLE_ADDITIONAL_CONTENT);
    useEffect(() => {
        let timeToClearFilesFromList;
        if (generalUploadState !== GeneralStatus.COMPLETED &&
            generalUploadState !== GeneralStatus.ERROR) {
            clearTimeout(timeToClearFilesFromList);
            return;
        }
        timeToClearFilesFromList = setTimeout(() => {
            removeAllFileFromList();
        }, TIME_TO_CLOSE_FRAME_UPLOAD_AFTER_UPLOADS_COMPLETED);
        return () => {
            clearTimeout(timeToClearFilesFromList);
            timeToClearFilesFromList = undefined;
        };
    }, [generalUploadState, removeAllFileFromList]);
    return (_jsxs(Can, { I: 'upload', a: 'Media', children: [_jsxs("div", { className: styles.header, children: [_jsx("span", { children: t('upload.title') }), _jsx(Link, { to: String(process.env.NEXT_PUBLIC_UPLOAD_HELP_LINK?.replace('{{lang}}', i18n.language)), target: '_blank', rel: 'noopener noreferrer', onClick: () => {
                            EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.UPLOAD_ARTICLE_CLICK);
                        }, children: _jsxs("div", { className: styles.info, children: [_jsx("span", { children: t('upload.know_more') }), _jsx("img", { src: linkImg, alt: '', width: 14, height: 14 })] }) })] }), _jsx("div", { ref: ref, "data-testid": 'container-files', className: styles.content, children: _jsxs("div", { className: styles.transferItemContent, "data-testid": 'file-upload-component', children: [_jsx(ChaptersBatchItems, {}), isAdditionalContentEnabled && _jsx(SubtitleBatchItems, {}), _jsx(ImportMediaProviderList, {}), _jsx(FileUpload, {})] }) })] }));
});
FileUploadListContainer.displayName = 'FileUploadListContainer';
