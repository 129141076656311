import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HotProgress } from 'src/ui/cosmos/Progress';
import { faClose } from 'src/application/icons/fontAwesome/light';
import { IconButton } from 'src/ui/cosmosWeb/IconButton';
import { TextTruncate } from 'src/ui/TextTruncate';
import styles from './styles.module.scss';
const Progress = ({ item }) => {
    return (_jsxs("div", { "data-testid": 'transfer-state-manager-progress', className: styles.progress, children: [_jsx("div", { className: styles.name, children: _jsx(TextTruncate, { children: _jsx("div", { className: styles.fileName, children: item.title }) }) }), item.onClickToCancel && (_jsx(IconButton, { icon: faClose, className: styles.icon, onClick: item.onClickToCancel, "data-testid": 'button-remove-file' })), _jsx("div", { className: styles.progressBar, children: _jsx(HotProgress, { value: item.progress || 0, color: 'success', thickness: 'small' }) })] }));
};
export default Progress;
