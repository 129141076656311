import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { Button } from 'src/ui/cosmosWeb/Button';
import { FontAwesomeIcon } from 'src/application/icons/fontAwesome';
import { faArrowUpFromBracket, faCircleX, } from 'src/application/icons/fontAwesome/light';
import { useUploadFile } from 'src/application/hooks/useUploadFile';
import { Toast } from 'src/ui/cosmos/Toast';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { Paragraph } from 'src/ui/cosmosWeb/Paragraph';
import { Trans, useTranslation } from 'react-i18next';
import { useOutletContext, useParams } from 'react-router-dom';
import ThumbnailImage from '../../components/ThumbnailImage';
import styles from './styles.module.scss';
import { CONFIG_UPLOAD, INITIAL_FILE_CONFIG_ALLOWED } from './constants';
export const ThumbnailUpload = () => {
    const { mediaCode } = useParams();
    const [file, setFile] = useState({});
    const [hasPendingFile, setHasPendingFile] = useState(false);
    const { uploadFile } = useUploadFile({
        apiUploadUrl: CONFIG_UPLOAD(mediaCode),
    });
    const [loading, setLoading] = useState();
    const [error, setError] = useState(false);
    const { t } = useTranslation();
    const onFileSelected = useCallback((event) => {
        if (!event.target?.files?.[0])
            return;
        setHasPendingFile(true);
        setFile(event.target.files[0]);
    }, []);
    const sendUpload = useCallback(() => {
        setLoading(true);
        uploadFile({
            file,
            callbacks: {
                onFail: () => {
                    setError(true);
                },
                onSuccess: () => {
                    setLoading(false);
                    setHasPendingFile(false);
                    const message = t('thumbnail.message.success');
                    Toast.success({ message });
                    EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.SETTINGS_MEDIA_THUMB_UPLOAD);
                },
            },
            options: {
                metaData: {},
            },
        });
    }, [uploadFile, file, t]);
    const { setSaveButtonState, media } = useOutletContext();
    useEffect(() => {
        setSaveButtonState({
            show: true,
            enable: hasPendingFile,
            isLoading: loading,
            handleSave: sendUpload,
        });
        return () => {
            setSaveButtonState({
                show: false,
                enable: false,
                isLoading: false,
                handleSave: undefined,
            });
        };
    }, [hasPendingFile, loading, sendUpload, setSaveButtonState]);
    return (_jsxs("div", { className: styles.container, children: [_jsx("div", { className: styles.description, children: _jsx(Paragraph, { size: 'small', children: t('thumbnail.description') }) }), _jsx("div", { className: styles.content, children: _jsxs("div", { children: [_jsxs("div", { className: styles.upload, children: [_jsx("div", { className: styles.image, children: _jsx(ThumbnailImage, { "data-testid": 'input-file', isLoading: !!loading, file: file, mediaCode: media.code, type: media.type }) }), _jsxs("div", { className: styles.box, children: [_jsx(Paragraph, { size: 'micro', children: _jsx(Trans, { i18nKey: 'thumbnail.label', components: { strong: _jsx("strong", {}) } }) }), _jsxs(Button, { className: styles.btn, children: [_jsx("input", { "data-testid": 'thumb-input-file', accept: INITIAL_FILE_CONFIG_ALLOWED.supportedFiles.toString(), type: 'file', multiple: false, onChange: onFileSelected }), _jsx("div", { slot: 'icon', children: _jsx(FontAwesomeIcon, { color: '#F7F9FA', icon: faArrowUpFromBracket }) }), t('thumbnail.send')] })] })] }), error && (_jsxs("div", { className: styles.invalidMessage, children: [_jsx(FontAwesomeIcon, { icon: faCircleX }), t('thumbnail.error.generic')] }))] }) })] }));
};
