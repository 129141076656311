import { useMemo } from 'react';
import { useListSupportedLanguages } from '../../Shared/hooks/useListSupportedLanguages';
import { useListSubtitlesByMediaCode } from './queries/useListSubtitlesByMediaCode';
import { useAddSubtitleToMedia } from './mutation/useAddSubtitleToMedia';
import { useToggleSubtitleStatus } from './mutation/useToggleSubtitleStatus';
import { useDeleteSubtitle } from './mutation/useDeleteSubtitle';
import { useTranslateSubtitle } from './mutation/useTranslateSubtitle';
import { useTranscribeSubtitle } from './mutation/useTranscribeSubtitle';
export const useSubtitle = ({ mediaCode }) => {
    const { allSupportedLanguages } = useListSupportedLanguages();
    const { subtitlesListFromMedia } = useListSubtitlesByMediaCode({ mediaCode });
    const { mutationAddSubtitle } = useAddSubtitleToMedia({ mediaCode });
    const { mutationToggleSubtitleStatus } = useToggleSubtitleStatus();
    const { deleteSubtitle } = useDeleteSubtitle();
    const { translateSubtitle } = useTranslateSubtitle();
    const { transcribeSubtitle } = useTranscribeSubtitle();
    const subtitlesAvailableFromMedia = useMemo(() => allSupportedLanguages.data?.filter((element) => !subtitlesListFromMedia.data?.find((_el) => _el.languageCode === element)) || [], [allSupportedLanguages.data, subtitlesListFromMedia.data]);
    const isUploading = mutationAddSubtitle.isLoading;
    const isLoading = allSupportedLanguages.isLoading ||
        deleteSubtitle.isLoading ||
        isUploading ||
        subtitlesListFromMedia.isLoading ||
        translateSubtitle.isLoading ||
        transcribeSubtitle.isLoading;
    return {
        deleteSubtitle,
        isLoading,
        mutationAddSubtitle,
        mutationToggleSubtitleStatus,
        subtitlesAvailableFromMedia,
        subtitlesListFromMedia,
        translateSubtitle,
        transcribeSubtitle,
        isUploading,
    };
};
