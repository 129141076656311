import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-lines */
import { useCallback, useEffect, useRef } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { Loading } from 'src/ui/cosmos/Loading';
import { ContentFeedback } from 'src/ui/ContentFeedback';
import { useWarnIfUnsavedChanges } from 'src/application/hooks/useWarnIfUnsavedChanges';
import { usePlayerEvents } from 'src/application/hooks/usePlayerEvents';
import BasicConfirmationModal from 'src/ui/Modal/components/BasicConfirmationModal';
import { useTranslation } from 'react-i18next';
import contentErrorImg from 'public/assets/svg/content_error.svg';
import { useOutletContext, useParams } from 'react-router-dom';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import useFeatureToggle from 'src/application/hooks/useFeatureToggle';
import { FeatureToggle } from 'src/application/interfaces/FeatureToggle';
import useAccounts from 'src/domain/User/Accounts/hooks/useAccounts';
import styles from './styles.module.scss';
import { ControlPanel } from './components/ControlPanel';
import PlayerPreview from './components/PlayerPreview';
import { ControlPlayback } from './interfaces/index';
import usePlaybackControlCustomizer from './hooks/usePlaybackControlCustomizer';
import useUpdatePlaybackControlCustomizer from './hooks/useUpdatePlaybackControlCustomizer';
const PlaybackControlCustomizer = () => {
    const { mediaCode } = useParams();
    const playerRef = useRef(null);
    const { setComponentsVisibility } = usePlayerEvents(playerRef);
    const { currentAccount } = useAccounts();
    const { isEnabled } = useFeatureToggle({
        accountOwnerMarketplaceId: currentAccount.accountOwnerMarketplaceId,
    });
    const isChaptersToggleControlEnabled = isEnabled(FeatureToggle.HOTMART_PLAYER_ENABLE_CONTROL_CHAPTERS);
    const { t } = useTranslation();
    const { isLoading, isError, preferences, togglePreference, hasUnsavedChanges, } = usePlaybackControlCustomizer({ mediaCode });
    const updatePreferencesControl = useUpdatePlaybackControlCustomizer({
        mediaCode,
    });
    useWarnIfUnsavedChanges({
        mustConfirmExitFromPage: hasUnsavedChanges,
        confirmation: async () => {
            const isConfirmed = await NiceModal.show(BasicConfirmationModal, {
                title: t `controls.modal.title`,
                description: t `controls.modal.description`,
                buttons: {
                    yes: {
                        title: t `controls.modal.buttons.yes`,
                    },
                    no: {
                        title: t `controls.modal.buttons.no`,
                        color: 'tertiary',
                        context: 'neutral',
                    },
                },
            });
            return !!isConfirmed;
        },
    });
    useEffect(() => {
        if (playerRef.current) {
            setComponentsVisibility(preferences);
        }
    }, [preferences, setComponentsVisibility]);
    const handleSave = useCallback(() => {
        updatePreferencesControl.mutate({
            preferences,
        });
    }, [preferences, updatePreferencesControl]);
    const { setSaveButtonState } = useOutletContext();
    useEffect(() => {
        setSaveButtonState({
            show: true,
            enable: hasUnsavedChanges,
            isLoading: updatePreferencesControl.isLoading,
            handleSave,
        });
        return () => {
            setSaveButtonState({
                show: false,
                enable: false,
                isLoading: false,
                handleSave: undefined,
            });
        };
    }, [
        setSaveButtonState,
        hasUnsavedChanges,
        handleSave,
        updatePreferencesControl.isLoading,
    ]);
    if (isLoading)
        return _jsx(Loading, {});
    if (isError)
        return (_jsx(ContentFeedback, { title: t('general.load_content_error.title'), description: t('general.load_content_error.description'), svg: contentErrorImg }));
    return (_jsxs("div", { className: styles.pageContainer, children: [_jsx("div", { className: styles.descriptionWrapper, children: _jsx("p", { className: styles.description, children: t('controls.description') }) }), _jsxs("div", { className: styles.componentsWrapper, children: [_jsx("div", { className: styles.playerPreview, children: _jsx(PlayerPreview, { mediaCode: mediaCode, ref: playerRef }) }), _jsxs("div", { className: styles.controlsPanel, children: [_jsxs(ControlPanel.Root, { title: t('controls.settings.details.title'), children: [_jsx(ControlPanel.Option, { control: ControlPlayback.MEDIA_TITLE, enabled: preferences.mediaTitle, "data-testid": 'media-title-toggle', onChange: () => {
                                            togglePreference({
                                                controlName: ControlPlayback.MEDIA_TITLE,
                                            });
                                        }, children: t('controls.settings.details.title_control') }), isChaptersToggleControlEnabled && (_jsx(ControlPanel.Option, { control: ControlPlayback.CHAPTERS, enabled: preferences.chapters, onChange: () => {
                                            togglePreference({
                                                controlName: ControlPlayback.CHAPTERS,
                                            });
                                            EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.SETTINGS_MEDIA_CONTROLS_CHAPTERS);
                                        }, children: t('controls.settings.details.chapters_control') }))] }), _jsxs(ControlPanel.Root, { title: t('controls.settings.view_and_tools.title'), children: [_jsx(ControlPanel.Option, { control: ControlPlayback.FULL_SCREEN, enabled: preferences.fullScreen, "data-testid": 'fullscreen-toggle', onChange: () => {
                                            togglePreference({
                                                controlName: ControlPlayback.FULL_SCREEN,
                                            });
                                        }, children: t('controls.settings.view_and_tools.full_screen') }), _jsx(ControlPanel.Option, { control: ControlPlayback.PICTURE_IN_PICTURE, enabled: preferences.pictureInPicture, "data-testid": 'picture-in-picture-toggle', onChange: () => {
                                            togglePreference({
                                                controlName: ControlPlayback.PICTURE_IN_PICTURE,
                                            });
                                        }, children: t('controls.settings.view_and_tools.picture_in_picture') }), _jsx(ControlPanel.Option, { control: ControlPlayback.PERSONAL_NOTES, enabled: preferences.personalNotes, "data-testid": 'personal-notes-toggle', onChange: () => {
                                            togglePreference({
                                                controlName: ControlPlayback.PERSONAL_NOTES,
                                            });
                                        }, children: t('controls.settings.view_and_tools.personal_notes') })] }), _jsxs(ControlPanel.Root, { title: t('controls.settings.speed.title'), children: [_jsx(ControlPanel.Option, { control: ControlPlayback.SEEK_BAR, enabled: preferences.seekBar, "data-testid": 'seekbar-toggle', onChange: () => {
                                            togglePreference({
                                                controlName: ControlPlayback.SEEK_BAR,
                                            });
                                        }, children: t('controls.settings.speed.reproduction_bar') }), _jsx(ControlPanel.Option, { "data-testid": 'rewind-toggle', control: ControlPlayback.REWIND, enabled: preferences.rewind, onChange: () => {
                                            togglePreference({
                                                controlName: ControlPlayback.REWIND,
                                            });
                                        }, children: t('controls.settings.speed.retreat') }), _jsx(ControlPanel.Option, { "data-testid": 'forward-toggle', control: ControlPlayback.FORWARD, enabled: preferences.forward, onChange: () => {
                                            togglePreference({
                                                controlName: ControlPlayback.FORWARD,
                                            });
                                        }, children: t('controls.settings.speed.advance') }), _jsx(ControlPanel.Option, { control: ControlPlayback.SPEED_CONTROL, enabled: preferences.speedControl, "data-testid": 'speed-control-toggle', onChange: () => {
                                            togglePreference({
                                                controlName: ControlPlayback.SPEED_CONTROL,
                                            });
                                        }, children: t('controls.settings.speed.speed_controls') })] })] })] })] }));
};
export default PlaybackControlCustomizer;
