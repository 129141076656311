import { list, generate, retry } from 'src/infrastructure/endpoints/chapters';
class ChaptersService {
    async list() {
        return list();
    }
    async generate({ mediaCodes, language }) {
        return generate({ mediaCodes, language });
    }
    async retry(id) {
        return retry(id);
    }
}
export default new ChaptersService();
