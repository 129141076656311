import { useQuery } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import SubtitleService from '../../services/SubtitleService';
import { SubtitleStatus } from '../../interfaces/subtitle';
import { useListSupportedLanguages } from '../../../Shared/hooks/useListSupportedLanguages';
export const REFETCH_INTERVAL = 10000; // 10sec
export const useListSubtitlesByMediaCode = ({ mediaCode }) => {
    const { allSupportedLanguages } = useListSupportedLanguages();
    const subtitlesListFromMedia = useQuery({
        queryKey: [QueryKeys.SUBTITLE, mediaCode],
        queryFn: () => SubtitleService.list({
            mediaCode,
        }),
        enabled: !allSupportedLanguages.isLoading,
        refetchInterval: (data) => {
            const areThereSubtitleProcessing = data?.find((item) => item.status === SubtitleStatus.PROCESSING);
            if (areThereSubtitleProcessing) {
                return REFETCH_INTERVAL;
            }
            return false;
        },
    });
    return {
        subtitlesListFromMedia,
    };
};
