import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card } from 'src/ui/cosmos/Card';
import { CardBody } from 'src/ui/cosmos/Card/Body';
import { CardHeader } from 'src/ui/cosmos/Card/Header';
import { Button } from 'src/ui/cosmosWeb/Button';
import { Heading } from 'src/ui/cosmosWeb/Heading';
import { useTranslation } from 'react-i18next';
import { useMediaLatest } from 'src/domain/Media/hooks';
import { useNavigate } from 'react-router-dom';
import ContentLoader from 'src/ui/cosmos/ContentLoader';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { UploadAndImportModal } from 'src/domain/Media/components/UploadAndImport/UploadAndImportModal';
import NiceModal from '@ebay/nice-modal-react';
import { Can } from 'src/infrastructure/libs/CASL';
import MediaItem from '../../components/MediaItem';
import styles from './styles.module.scss';
const LastUploads = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { data: medias, isLoading } = useMediaLatest({ amount: 5 });
    if (isLoading)
        return (_jsx(ContentLoader, { className: styles.contentLoader, "data-testid": 'last-uploads-content-loader' }));
    return (_jsxs(Card, { className: styles.container, children: [_jsxs(CardHeader, { className: styles.header, children: [_jsx(Heading, { type: 'h3', size: 'small', children: t('home.default_home.last_uploads_card.title') }), _jsxs("div", { className: styles.headerActions, children: [_jsx(Button, { size: 'medium', variation: 'tertiary', "data-testid": 'see-all-medias-button', onClick: () => {
                                    navigate('library?filter=medias');
                                    EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.HOME_VIEW_ALL_MEDIA);
                                }, children: t('home.buttons.see_all') }), _jsx(Can, { I: 'upload', a: 'Media', children: _jsx(Button, { "data-testid": 'upload-action-button', size: 'medium', onClick: () => {
                                        NiceModal.show(UploadAndImportModal);
                                        EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.HOME_NEW_UPLOAD_HEAVY_USER);
                                    }, children: t('home.buttons.new_upload') }) })] })] }), _jsx(CardBody, { children: medias?.map((media) => _jsx(MediaItem, { media: media }, media.code)) })] }));
};
export default LastUploads;
