import { NiceModal } from 'src/infrastructure/libs/NiceModal';
import { useTranslation } from 'react-i18next';
import loadingGif from 'public/assets/gif/loading.gif';
import contentErrorImg from 'public/assets/svg/content_error.svg';
import { ChaptersBatchFeedbackModal } from '../components/ChaptersBatchFeedbackModal';
export const useShowChaptersBatchFeedbackModal = () => {
    const { t } = useTranslation();
    const showSuccessMessage = () => {
        NiceModal.show(ChaptersBatchFeedbackModal, {
            image: loadingGif,
            title: t('additional_content.modal.chapters.success_modal.title'),
            description: t('additional_content.modal.chapters.success_modal.description'),
            buttonLabel: t('additional_content.modal.chapters.success_modal.button_ok'),
        });
    };
    const showErrorMessage = (retry) => {
        NiceModal.show(ChaptersBatchFeedbackModal, {
            image: contentErrorImg,
            title: t('additional_content.modal.chapters.error_modal.title'),
            description: t('additional_content.modal.chapters.error_modal.description'),
            buttonLabel: t('additional_content.modal.chapters.error_modal.try_again'),
            retryFn: retry,
        });
    };
    return { showSuccessMessage, showErrorMessage };
};
