import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from 'src/ui/cosmosWeb/Button';
import { TextTruncate } from 'src/ui/TextTruncate';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
const ErrorComponent = ({ item }) => {
    const { t } = useTranslation();
    return (_jsxs("div", { "data-testid": 'transfer-state-manager-error', className: styles.error, children: [_jsx("div", { className: styles.name, children: _jsx(TextTruncate, { children: _jsx("div", { className: styles.fileName, children: item.title }) }) }), _jsx("div", { className: styles.message, children: _jsxs("span", { children: [item.errorMessage, item.onClickToRetry && (_jsx(Button, { className: styles.button, context: 'danger', variation: 'tertiary', "data-testid": 'button-retry-send-file', onClick: item.onClickToRetry, children: t('upload.upload_status.error.try_again') }))] }) })] }));
};
export default ErrorComponent;
