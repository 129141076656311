// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fhKZO1GJVVN59vnUJfCM{margin:-0.5rem 0 1rem 0}.fhKZO1GJVVN59vnUJfCM .qn2MMbceIcOTzdWNJ4tY{border-radius:var(--hc-size-border-large);margin:.5rem 0;height:4.375rem}.fhKZO1GJVVN59vnUJfCM .qn2MMbceIcOTzdWNJ4tY:last-child{margin-bottom:0 !important}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/Chapters/components/ChaptersBatchItems/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,uBAAA,CAEA,4CACE,yCAAA,CACA,cAAA,CACA,eAAA,CAEA,uDACE,0BAAA","sourcesContent":[".container {\n  margin: -0.5rem 0 1rem 0;\n\n  .info {\n    border-radius: var(--hc-size-border-large);\n    margin: 0.5rem 0;\n    height: 4.375rem;\n\n    &:last-child {\n      margin-bottom: 0 !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `fhKZO1GJVVN59vnUJfCM`,
	"info": `qn2MMbceIcOTzdWNJ4tY`
};
export default ___CSS_LOADER_EXPORT___;
