import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert } from 'src/ui/cosmos/Alert';
import { Button } from 'src/ui/cosmosWeb/Button';
import { Select, SelectGroup, SelectOption } from 'src/ui/cosmos/Select';
import { Heading } from 'src/ui/cosmosWeb/Heading';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Loading } from 'src/ui/cosmos/Loading';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { useListSupportedLanguages } from '../../../Shared/hooks/useListSupportedLanguages';
import { useShowChaptersBatchFeedbackModal } from '../../hooks/useShowChaptersBatchFeedbackModal';
import { useChaptersBatch } from '../../hooks/useChaptersBatch';
import styles from './styles.module.scss';
export const ChaptersBatchContainer = ({ previousPage, medias, onSubmitChaptersBatch, }) => {
    const { t } = useTranslation();
    const { allSupportedLanguages } = useListSupportedLanguages();
    const [language, setLanguage] = useState('');
    const { generateChaptersBatch } = useChaptersBatch();
    const feedbackModal = useShowChaptersBatchFeedbackModal();
    const handleGenerateChaptersBatch = async () => {
        const mediaCodes = medias.map((media) => media.code);
        EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.ADDITIONAL_CONTENT_LANGUAGES_CHAPTERS, 'button', [
            {
                key: 'language',
                value: language,
            },
        ]);
        try {
            await generateChaptersBatch.mutateAsync({
                mediaCodes,
                language,
            });
            feedbackModal.showSuccessMessage();
        }
        catch {
            feedbackModal.showErrorMessage(handleGenerateChaptersBatch);
        }
        finally {
            onSubmitChaptersBatch();
        }
    };
    if (allSupportedLanguages.isLoading) {
        return _jsx(Loading, { full: true });
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", { slot: 'content', children: _jsxs("div", { className: styles.content, children: [_jsxs("div", { className: styles.contentHeader, children: [_jsx(Heading, { type: 'h2', size: 'small', className: styles.modalContentTitle, children: t('additional_content.modal.chapters.header_title') }), _jsx("p", { className: styles.contentDescription, children: t('additional_content.modal.chapters.content_title') })] }), _jsx("div", { children: _jsx(Alert, { className: `hot-alert--info ${styles.alert}`, children: t('additional_content.modal.chapters.alert') }) }), _jsx("div", { className: styles.chaptersContainer, children: _jsxs("div", { className: styles.inputContainer, children: [_jsx("p", { className: styles.inputLabel, children: t('additional_content.modal.chapters.form.label_source_language') }), _jsx(Select, { className: styles.select, onChange: (event) => setLanguage(event.detail.value), placeholder: t('additional_content.modal.chapters.form.label_source_language'), children: _jsx(SelectGroup, { children: allSupportedLanguages?.data?.map((language) => (_jsx(SelectOption, { value: language, children: t(`subtitle.supported_languages.${language}`) }, language))) }) })] }) })] }) }), _jsxs("div", { slot: 'controls', className: styles.controls, children: [_jsx(Button, { variation: 'tertiary', onClick: () => {
                            EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.ADDITIONAL_CONTENT_CHAPTERS_RETURN);
                            previousPage();
                        }, children: t('additional_content.modal.chapters.button_back') }), _jsx(Button, { variation: 'primary', disabled: language === '', onClick: () => {
                            EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.ADDITIONAL_CONTENT_CLICK_GENERATE_CHAPTERS);
                            handleGenerateChaptersBatch();
                        }, "data-testid": 'button-generate-batch-chapters', children: t('additional_content.modal.chapters.button_generate_chapters') })] })] }));
};
