import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Loading } from 'src/ui/cosmos/Loading';
import { Alert } from 'src/ui/cosmosWeb/Alert';
import { useTranslation } from 'react-i18next';
import { Switch } from 'src/ui/cosmos/Switch';
import { NiceModal } from 'src/infrastructure/libs/NiceModal';
import BasicConfirmationModal from 'src/ui/Modal/components/BasicConfirmationModal';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { useDeleteMediaShare } from '../../hooks/useDeleteMediaShare';
import { useAddMediaShare } from '../../hooks/useAddMediaShare';
import useMediasShare from '../../hooks/useMediaShare';
import MediaShareCopyToClipboard from '../../components/MediaShareCopyToClipboard';
import styles from './styles.module.scss';
import MediaItem from './components/MediaItem';
const LimitedShare = ({ mediaCode, link, embedCode, onCopyLink, onCopyEmbedCode, }) => {
    const { t } = useTranslation();
    const { isLoading, isMediaShared, isMaxAmountShared, data: mediasShared, } = useMediasShare();
    const addMediaShare = useAddMediaShare();
    const deleteMediaShare = useDeleteMediaShare();
    const isShared = isMediaShared(mediaCode);
    const handleToggleMediaShare = async (isEnabling) => {
        if (isEnabling) {
            addMediaShare.mutateAsync({ mediaCode });
            EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.SETTINGS_MEDIA_SHARE_FREEMIUM_ENABLE);
            return;
        }
        const isConfirmed = await NiceModal.show(BasicConfirmationModal, {
            title: t `share.disable_modal.title`,
            description: t `share.disable_modal.description`,
            buttons: {
                yes: {
                    title: t `share.disable_modal.buttons.yes`,
                },
                no: {
                    title: t `share.disable_modal.buttons.no`,
                    color: 'tertiary',
                    context: 'neutral',
                },
            },
        });
        if (isConfirmed) {
            deleteMediaShare.mutateAsync({ mediaCode });
            EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.SETTINGS_MEDIA_SHARE_FREEMIUM_DISABLE);
        }
    };
    if (isLoading)
        return _jsx(Loading, { "data-testid": 'share-loading', full: true });
    return (_jsxs(_Fragment, { children: [_jsx("div", { children: _jsx(Switch, { name: 'toggle-media-share', id: 'toggle-media-share', label: t('general.share'), checked: isShared, onChange: ({ target: { checked } }) => {
                        handleToggleMediaShare(checked);
                    }, disabled: isMaxAmountShared && !isShared }) }), isMaxAmountShared && !isShared ? (_jsxs(_Fragment, { children: [_jsx(Alert, { "data-testid": 'alert-max-share-amount', context: 'danger', children: t('share.max_share_quantity_alert') }), _jsxs("div", { className: styles.mediaShareList, children: [_jsx("strong", { children: t('share.max_share_quantity_information') }), mediasShared.map(({ mediaCode }) => (_jsx(MediaItem, { mediaCode: mediaCode }, mediaCode)))] })] })) : (_jsx(Alert, { "data-testid": 'alert-share-limitation-information', context: 'info', children: t('share.quantity_limitation_information') })), isShared && (_jsx(MediaShareCopyToClipboard, { link: link, embedCode: embedCode, onCopyEmbedCode: onCopyEmbedCode, onCopyLink: onCopyLink }))] }));
};
export default LimitedShare;
